<template>
  <div id="schooling">
    <v-index>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>教学</el-breadcrumb-item>
        <el-breadcrumb-item><a @click="$router.back()" style="color: #000000;">授课列表</a></el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">授课安排</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <el-steps :active="current">
          <el-step title="授课学期" />

          <el-step title="授课老师" />

          <el-step title="授课班级" />

          <el-step title="课程选择" />

          <el-step title="授课参数" />
        </el-steps>

        <template v-if="current === 0">
          <el-row>
            <el-col :span="8">
              <div class="box">
                <div class="content-title">校区</div>
                <ul class="discipline">
                  <template v-for="item in campus">
                    <li :key="item.id" :class="{ selected: selectionCampus === item.id }" @click="onSelectionCampus(item)">
                      {{ item.name }}
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>

            <el-col :span="8">
              <div class="box">
                <div class="content-title">学期</div>
                <div v-if="semesters.length === 0" class="empty-data">
                  无学期数据
                </div>
                <ul class="discipline">
                  <template v-for="item in semesters">
                    <li :key="item.id" :class="{ selected: selectionSemester === item.id }" @click="onSelectionSemester(item)">
                      {{ item.name }}
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>

          </el-row>
        </template>

        <!-- 老师 -->
        <el-row v-if="current === 1">
          <el-col :span="8">
            <div class="box">
              <div class="content-title">老师：</div>
              <div class="content-search">
                <el-input v-model="searchTeacherName" placeholder="搜索老师姓名" @input="onSearchTeacher" clearable />
              </div>
              <div v-if="teachers.length === 0 && !teacherLoading" class="empty-data">
                没找到老师数据
              </div>
              <ul class="discipline" v-infinite-scroll="getTeacher">
                <template v-for="item in teachers">
                  <li :key="item.id" :class="{ selected: selectionTeachers.includes(item.id) }" @click="onSelectionTeacher(item)">
                    {{ item.username }}
                  </li>
                </template>
                <li v-show="teacherLoading">
                  加载中...
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :span="16">
            <div class="box">
              <div class="content-title">已选择({{selectionTeachers.length}}位)：</div>
              <div v-if="selectionTeachers.length === 0" class="empty-data">
                无老师数据
              </div>
              <div class="wrapper-selected">
                <template v-for="item in teachers.filter(_ => selectionTeachers.includes(_.id))">
                  <el-tag :key="item.id" closable :class="{ selected: selectionClass.includes(item.id) }" @close="onSelectionTeacher(item)">
                    {{ item.username }}
                  </el-tag>
                </template>
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- 年级班级 -->
        <el-row v-if="current === 2">
          <el-col :span="8">
            <div class="box">
              <div class="content-title">年级：</div>
              <ul class="discipline">
                <template v-for="item in grades">
                  <li :key="item.id" :class="{ selected: selectionGrade === item.id }" @click="onSelectionGrade(item)">
                    {{ item.name }}
                  </li>
                </template>
              </ul>
            </div>
          </el-col>

          <el-col :span="16">
            <div class="box">
              <div class="content-title">班级选择：</div>
              <div v-if="classes.length === 0" class="empty-data">
                无班级数据
              </div>
              <ul class="discipline" v-loading="loadingClass">
                <template v-for="item in classes">
                  <li :key="item.id" :class="{ selected: selectionClass.includes(item.id) }" @click="onSelectionClass(item)">
                    {{ item.name }}
                  </li>
                </template>
              </ul>
            </div>
          </el-col>
        </el-row>

        <!-- 课程 -->
        <el-row v-if="current === 3">
          <el-col :span="8">
            <div class="box">
              <div class="content-title">课程：</div>
              <div class="content-search">
                <el-input v-model="searchDisciplineName" placeholder="搜索课程" @input="onSearchDiscipline" clearable />
              </div>
              <div v-if="disciplines.length === 0 && !disciplineLoading" class="empty-data">
                没找到课程数据
              </div>
              <ul class="discipline" v-infinite-scroll="getTeacher">
                <template v-for="item in disciplines">
                  <li :key="item.id" :class="{ selected: selectionDisciplines.includes(item.id) }" @click="onSelectionDiscipline(item)">
                    {{ item.name }}
                  </li>
                </template>
                <li v-show="disciplineLoading">
                  加载中...
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :span="16">
            <div class="box">
              <div class="content-title">已选择({{selectionDisciplines.length}})：</div>
              <div v-if="selectionDisciplines.length === 0" class="empty-data">
                无课程数据
              </div>
              <div class="wrapper-selected">
                <template v-for="item in disciplines.filter(_ => selectionDisciplines.includes(_.id))">
                  <el-tag :key="item.id" closable :class="{ selected: selectionDisciplines.includes(item.id) }" @close="onSelectionDiscipline(item)">
                    {{ item.name }}
                  </el-tag>
                </template>
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- 参数 -->
        <template v-if="current === 4">
          <div>
            <div>
              <div>

                <el-table :data="teachers.filter(_ => selectionTeachers.includes(_.id))">
                  <el-table-column label="授课老师" prop="username" />

                  <el-table-column label="周课时">
                    <template v-slot="{ row }">
                      <div v-for="gradeClass in classes.filter(_ => selectionClass.includes(_.id))" :key="gradeClass.id" class="grade-class">
                        <div class="grade-class-name">
                          {{gradeClass.name}}：
                        </div>
                        <el-input-number size="small" :value="getTeacherWeekHour(row, gradeClass)" @input="onTeacherWeekHour(row, gradeClass, $event)" />
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

        </template>

        <div class="footer">
          <el-button @click="onPrev" :disabled="current === 0" :loading="subing">
            上一步
          </el-button>
          <el-button type="primary" @click="onNext" :loading="subing">
            {{current === 4 ? '确认提交' : '下一步'}}
          </el-button>
        </div>
      </div>
    </v-index>
  </div>
</template>

<script>
import VIndex from "@/components/common";
import _ from "lodash";
import v2Teacher from "@/api/v2/teacher";
export default {
  name: "add",
  components: {VIndex},
  data() {
    return {
      current: 0,
      // 已选校区
      selectionCampus: 0,
      // 已选学期
      selectionSemester: 0,
      // 老师
      teachers: [],
      teacherPage: 1,
      teacherLoading: false,
      // 年级
      grades: [],
      // 已选年级
      selectionGrade: 0,
      // 班级
      classes: [],
      // 已选班级
      selectionClass: [],
      // 班级加载状态
      loadingClass: false,
      // 搜索老师名称
      searchTeacherName: '',
      // 已选老师
      selectionTeachers: [],
      // 课程列表
      disciplines: [],
      disciplinePage: 1,
      disciplineLoading: false,
      // 已选课程
      selectionDisciplines: [],
      // 搜索课程
      searchDisciplineName: '',
      // 老师课时
      teacherWeekHour: {},
      subing: false,
    }
  },
  computed: {
    campus() {
      return this.$store.getters['common/campus']
    },
    semesters() {
      return this.$store.getters['common/semesters'].filter(_ => this.selectionCampus === _.school_campus_id)
    },
  },
  watch: {
    current(val) {
      if (val === 1) {
        this.getTeacher()
      }
      else if (val === 2) {
        this.getGrade()
      }
      else if (val === 3) {
        this.getDiscipline()
      }
      else if (val === 4) {
        // 初始化参数
        const obj = {};
        this.selectionTeachers.forEach(teacherId => {
          const data = {};
          this.selectionClass.forEach(classId => {
            data[classId] = 30;
          })
          obj[teacherId] = data;
        })
        this.teacherWeekHour = obj
      }
    }
  },
  mounted() {
    // this.selectionCampus = 1
    // this.selectionSemester = 33
    // this.selectionTeachers = [427, 162, 161]
    // setTimeout(() => this.current++, 1000)
    // setTimeout(() => this.current++, 2000)
    // setTimeout(() => {
    //   this.current++
    //   this.onSelectionGrade(_.last(this.grades))
    //   setTimeout(() => {
    //     this.selectionClass = [65, 64]
    //     this.selectionDisciplines = [452, 451]
    //     this.current++
    //   }, 1000)
    // }, 3000)
  },
  methods: {
    // 校区选择
    onSelectionCampus(item) {
      this.selectionCampus = item.id;
    },
    // 学期选择
    onSelectionSemester(item) {
      this.selectionSemester = item.id;
    },
    // 搜索老师
    onSearchTeacher: _.debounce(function () {
      this.teacherPage = 1
      this.teachers = []
      this.getTeacher()
    }, 500),
    // 获取老师
    async getTeacher() {
      if (this.teacherLoading) {
        return;
      }
      this.teacherLoading = true
      const filter = {}
      if (this.searchTeacherName) {
        filter.username = this.searchTeacherName
      }
      const resp = await this.$api.setting.userList({
        page: this.teacherPage,
        limit: 15,
        filter,
      }).finally(() => this.teacherLoading = false)
      if (resp.data.code === 1) {
        this.teachers = [...this.teachers, ...resp.data.data.rows];
        this.teacherPage++;
      } else {
        this.$message.error(resp.data.msg);
      }
    },
    // 老师选择
    onSelectionTeacher(item) {
      const index = this.selectionTeachers.findIndex(_ => _ === item.id)
      if (index === -1) {
        this.selectionTeachers.push(item.id)
      } else {
        this.selectionTeachers.splice(index, 1)
      }
    },
    // 获取年级数据
    async getGrade() {
      const resp = await this.$api.setting.getGradeList({
        filter: {
          school_campus_id: this.selectionCampus,
        },
        sort: {
          createtime: 'asc'
        }
      })
      if (resp.data.code === 1) {
        this.grades = resp.data.data.rows
      } else {
        this.$message.error(resp.data.msg)
      }
    },
    // 选择年级
    onSelectionGrade(grade) {
      this.selectionGrade = grade.id
      this.selectionClass = []
      this.getGradeClass()
    },
    // 获取班级
    async getGradeClass() {
      this.loadingClass = true;
      const resp = await this.$api.setting.getClassList({
        filter: {
          grade_id: this.selectionGrade,
          school_campus_id: this.selectionCampus,
        }
      }).finally(() => this.loadingClass = false)
      if (resp.data.code === 1) {
        this.classes = resp.data.data.rows;
      } else {
        this.$message.error(resp.data.msg);
      }
    },
    // 选择班级
    onSelectionClass(item) {
      const index = this.selectionClass.findIndex(_ => _ === item.id)
      if (index > -1) {
        this.selectionClass.splice(index, 1)
      } else {
        this.selectionClass.push(item.id)
      }
    },
    // 搜索课程
    onSearchDiscipline: _.debounce(function () {
      this.disciplinePage = 1
      this.disciplines = []
      this.getDiscipline()
    }, 500),
    // 获取课程
    async getDiscipline() {
      if (this.disciplineLoading) {
        return;
      }
      this.disciplineLoading = true;
      const filter = {}
      if (this.searchDisciplineName) {
        filter.name = this.searchDisciplineName
      }
      const resp = await this.$api.setting.subjectIndex({
        page: this.disciplinePage,
        limit: 15,
        filter,
      })
          .finally(() => this.disciplineLoading = false)
      if (resp.data.code === 1) {
        this.disciplines = [...this.disciplines, ...resp.data.data.rows]
        this.disciplinePage++
      } else {
        this.$message.error(resp.data.msg)
      }
    },
    // 选择课程
    onSelectionDiscipline(item) {
      const index = this.selectionDisciplines.findIndex(_ => _ === item.id)
      if (index > -1) {
        this.selectionDisciplines.splice(index, 1)
      } else {
        this.selectionDisciplines.push(item.id)
      }
    },
    // 获取老师的班级周课时
    getTeacherWeekHour(teacher, gradeClass) {
      if (! this.teacherWeekHour[teacher.id]) {
        return 0
      }
      if (this.teacherWeekHour[teacher.id][gradeClass.id]) {
        return this.teacherWeekHour[teacher.id][gradeClass.id]
      }
      return 0
    },
    // 输入老师班级周课时
    onTeacherWeekHour(teacher, gradeClass, value) {
      this.$set(this.teacherWeekHour, teacher.id, {
        ...this.teacherWeekHour[teacher.id],
        [gradeClass.id]: Number(value),
      })
    },
    // 上一步
    onPrev() {
      this.current--
    },
    // 下一步
    onNext() {
      if (this.current === 0 && this.selectionSemester === 0) {
        this.$message.error('请选择学期')
        return
      }
      if (this.current === 1 && this.selectionTeachers.length === 0) {
        this.$message.error('请选择授课老师')
        return
      }
      if (this.current === 2 && this.selectionClass.length === 0) {
        this.$message.error('请选择要安排的授课班级')
        return
      }
      if (this.current === 3 && this.selectionDisciplines.length === 0) {
        this.$message.error('请选择要教学的课程')
        return
      }
      if (this.current === 4) {
        this.onSubmit();
        return;
      }
      this.current++
    },
    // 提交
    async onSubmit() {
      const form = {
        semester_id: this.selectionSemester,
        discipline_ids: this.selectionDisciplines,
        teachers: this.teacherWeekHour,
      }
      this.subing = true;
      await v2Teacher.add(form).finally(() => this.subing = false)

      this.$message.success('提交成功')
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  &-title {
    font-weight: 700;
    margin-bottom: 10px;
  }
  &-search {
    margin-bottom: 20px;
  }
}
.footer {
  text-align: right;
  padding: 20px;
}
.discipline {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 500px;
  overflow-y: auto;
  font-size: 14px;
  li {
    padding: 4px 8px;
    cursor: pointer;
    border: solid 1px #d3d3d3;
    margin-bottom: 6px;
    line-height: 30px;
    &.selected {
      background-color: rgb(179, 216, 255);
      border-color: rgb(179, 216, 255);
    }
  }
}
.box {
  padding: 10px;
}
.empty-data {
  color: #999;
  margin-top: 15px;
  border: solid 1px #f3f3f3;
  padding: 30px;
}
.class-list {
  list-style: none;
  margin: 0;
  padding: 10px;
  li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.wrapper-selected {
  padding: 10px;
  border: solid 1px #f3f3f3;
  min-height: 35px;
  ::v-deep .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.grade-class {
  margin-bottom: 10px;
  &-name {
    display: block;
  }
}
</style>